// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-photos-only-page-js": () => import("./../../src/pages/photos-only-page.js" /* webpackChunkName: "component---src-pages-photos-only-page-js" */),
  "component---src-pages-photos-school-page-js": () => import("./../../src/pages/photos-school-page.js" /* webpackChunkName: "component---src-pages-photos-school-page-js" */),
  "component---src-pages-test-flex-js": () => import("./../../src/pages/test-flex.js" /* webpackChunkName: "component---src-pages-test-flex-js" */),
  "component---src-pages-test-js": () => import("./../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

